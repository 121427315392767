
import { ToastContainer,toast } from 'react-toastify';
import {COLOR_ATTRIBUTE} from './colorconstant';

const showToast = (message,position,color,status) => {
    status===true?toast.success(message, {
        position: position,
        autoClose: 3000,
        success:`${COLOR_ATTRIBUTE.BG}-${color}`,
        hideProgressBar: true,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }):toast.error(message, {
      position: position,
      autoClose: 3000,
      error:`${COLOR_ATTRIBUTE.BG}-${color}`,
      hideProgressBar: true,
      closeButton: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  })
}

export { showToast };