import { capitalizeAllLetters, capitalizeFirstLetter } from "./common";

export const ROLES = {
  SUPER_ADMIN: "superadmin",
  CAMPUS: "campus",
  EMPLOYER: "employer",
  STUDENT: "student",
  ON_STUDENT: "oncampusstudent",
  OFF_STUDENT: "offcampusstudent",
  CORPORATE: "corporate",
  INTERVIEWER:"interviewer"
};


export const SAMPLEURL = {
  CAMPUSBULK:"https://easycampus-dev-images.s3.ap-south-1.amazonaws.com/campusbulk-sample.xlsx",
  CORPORATBULK:"https://easycampus-dev-images.s3.ap-south-1.amazonaws.com/corporatebulk-sample.xlsx",
  STUDENTBULK:"https://easycampus-dev-images.s3.ap-south-1.amazonaws.com/studentbulk-sample.xlsx"
}


export const SERVER_TYPE = {
  DEVELOPMENT:"development",
  PRODUCTION:"production",
  STAGING:"staging"
}

export const STATUS = {
  INITIAL: "Initial",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const ACTION = {
  ADD: "add",
  VIEW: "view",
  EDIT: "edit",
  DELETE: "delete",
  CHANGE_STATUS:"changeStatus"
};

export const ROUTE_STRING = {
  CAMPUS: "campus",
  CORPORATES: "corporates",
  STUDENT: "student",
};

export const MESSAGE = {
  LOGIN_SUCCESS: "Login Success.",
  LOGIN_FAILD: "Login Faild.",
  FULL_NAME_IS_REQUIRED: "Full name is required",
  EMAIL_OTP_IS_REQUIRED: "Email otp is required",
  ENATER_VALID_EMAIL_OTP: "Enter valid email OTP.",
  ENATER_VALID_MOBILE_OTP: "Enter valid mobile OTP.",
  MOBILE_OTP_IS_REQUIRED: "Mobile otp is required",
  NAME_IS_REQUIRED: "Name is required",
  EMAIL_IS_REQUIRED: "Email is required",
  MOBILE_IS_REQUIRED: "Mobile is required",
  PASSWORD_IS_REQUIRED: "Password is required",
  INVALID_EMAIL: "Please enter valid email",
  INVALID_PASSWORD: "Please enter valid password",
  INVALID_MOBILE: "Please enter valid number",
  INVALID_AADHAR: "Please enter valid aadhar number",
  CAMPUS_REQUIRED: "Campus name is required",
  SELECT_UNIVERSITY_NAME:"Select University name",
  UNIVERSITY_REQUIRED: "University name is required",
  SELECT_IS_UNIVERSITY_OR_COLLAGE_NAME:"Select is university or collage",
  UNIVERSITY_TYPE_IS_REQUIRED:"University type is required",
  COLLEGE_TYPE_IS_REQUIRED:"College type is required",
  APPROVED_BY_IS_REQUIRED:"Approved By is required",
  RECOGNIZED_BY_IS_REQUIRED:"Recognized by is required",
  STREAM_IS_REQUIRED:"Stream is required",
  SPECIALIZATION_IS_REQUIRED:"Specialization is required",
  COMPANY_REQUIRED: "Company name is required",
  STUDENT_REQUIRED: "Student name is required",
  STUDENT_TYPE_REQUIRED: "Student type is required",
  CAMPUS_CODE_REQUIRED: "Campus code is required",
  GRADE_IS_REQUIRED: "Grade is required",
  FAX_NUMBER_REQUIRED: "Fax Number is required",
  WEBSITE_REQUIRED: "Website is required",
  INVALID_WEBURL: "Weburl is Invalid",
  REGION_IS_REQUIRED: "Region is required",
  // REGISTERED_REGION_IS_REQUIRED: "registeredRegion is required",
  STATE_IS_REQUIRED: "State is required",
  DISTRICT_IS_REQUIRED: "District is required",
  CITY_IS_REQUIRED: "City is required",
  PINCODE_IS_REQUIRED: "Pincode is required",
  INVALID_PINCODE: "Pincode is Invalid",
  TPO_NAME_IS_REQUIRED: "Tpo Name is required",
  TPO_EMAIL_IS_REQUIRED: "Tpo Email is required",
  TPO_MOBILE_IS_REQUIRED: "Tpo Mobile is required",
  DEAN_NAME_IS_REQUIRED: "Dean Name is required",
  DEAN_EMAIL_IS_REQUIRED: "Dean Email is required",
  DEAN_MOBILE_IS_REQUIRED: "Dean Mobile is required",
  CAMPUS_LOGO_REQUIRED: "Campus logo is required",
  PLEASE_CLICK_UPLOAD_BUTTON:"Please click upload button.",
  DIGITAL_SIGNATURE_IS_REQUIRED: "Digital signature is required",
  SIGNATURE_IS_REQUIRED: "Signature is required",
  PICTURE_URL_IS_REQUIRED: "Profile picture is required",
  // TITLE_IS_IS_REQUIRED:"Title is Required",
  ABOUT_CAMPUS_IS_REQUIRED:"About Campus is required",
  WE_NEED_MORE_INFORMATION_ABOUT_YOUR_CAMPUS:"We Need More Information About Your Campus",
  ADDRESS_IS_REQUIRED: "Address is required",
  PHONE_IS_REQUIRED: "Phone Numebr is required",
  PLEASE_ENTER_VALID_LATITUDE:"Please Enter Valid Latitude",
  PLEASE_ENTER_VALID_LONGITUDE:"Please Enter Valid Longitude",
  INVALID_PHONE: "Phone Number is Invalid",
  HR_DESIGNATION_IS_REQUIRED: "HR Designation is Required",
  HR_NAME_IS_REQUIRED: "HR Name is required",
  HR_EMAIL_IS_REQUIRED: "HR Email is required",
  HR_MOBILE_IS_REQUIRED: "HR Mobile is required",
  FOUNDER_DESIGNATION_IS_REQUIRED: "Founder Designation is Required",
  FOUNDER_NAME_IS_REQUIRED: "Founder Name is required",
  FOUNDER_EMAIL_IS_REQUIRED: "Founder Email is required",
  FOUNDER_MOBILE_IS_REQUIRED: "Founder Mobile is required",
  CORPORATE_PHONE_IS_REQUIRED: "Corporate Phone is required",
  PAN_NUMBER_IS_REQUIRED: "Pan Number is required",
  INVALID_PAN_NUMBER: "Pan Number is Invalid",
  TAN_NUMBER_IS_REQUIRED: "Tan Number is required",
  INVALID_TAN_NUMBER: "Tan Number is Invalid",
  GST_NUMBER_IS_REQUIRED: "Gst Number is required",
  INVALID_GST_NUMBER: "Gst Number is Invalid",
  ESTABLISHED_YEAR_IS_REQUIRED: "Established Year is required",
  INDUSTRY_TYPE_IS_REQUIRED: "Industry Type is required",
  GROUPTURNOVER_IS_REQUIRED: "GroupTurnover is required",
  EMPLOYEE_STRENGTH_IS_REQUIRED: "Employee Strength is required",
  CORPORATE_ADDRESS_IS_REQUIRED: "Corporate Address is required",
  REGISTERED_ADDRESS_IS_REQUIRED: "Registered Address is required",
  CORPORATE_LOGO_REQUIRED: "Corporate logo is required",
  GROUP_COMPANIES_REQUIRED: "Group Companies is required",
  SELECT_ATLEAST_ONE: "Please select atleast one",
  WRITE_MORE_THAN_THREE_CHARACTERS: "Please enter more than three characters",
  INVALID_FAXNUMBER: "Fax Number is Invalid",
  CORPORATE_REQUIRED: "Cororate name is required",
  FIRST_NAME_IS_REQURED:"First name is required",
  LAST_NAME_IS_REQURED:"Last name is required",
  MIDDLE_NAME_IS_REQURED:"Middle name is required",
  CAMPUS_NAME_IS_REQURED:"Campus name is required",
  CAMPUS_EMAIL_IS_REQURED:"Campus email is required",
  CAMPUS_MOBILE_IS_REQURED:"Campus mobile is required",
  CORPORATE_NAME_IS_REQURED:"Corporate name is required",
  CORPORATE_EMAIL_IS_REQURED:"Corpoarate email is required",
  CORPORATE_MOBILE_IS_REQURED:"Corporate mobile is required",
  ENROLLMENT_NUMBER_IS_REQUIRED:"Enrollment Number is required",
  GENDER_IS_REQUIRED:"Gender is Required",
  FATHER_NAME_IS_REQUIRED: "Father name is required",
  MOTHER_NAME_IS_REQUIRED: "Mother name is required",
  DATE_OF_BIRTH: "Date of birth is required",
  PRESENT_ADDRESS :"Present Address is required",
  PREMANENT_ADDRESS :"Permanent Address is required",
  EMERGENCY_CONTACT_NUMBER : "Emergency Contact Number is required",
  ALTERNET_MOBILE_NUMBER : "Alternet Mobile Number is required",
  NATIONALITY : "Nationality is required",
  RELIGION : "Religion is required",
  ADHAR_NUMBER_IS_REQUIRED: "Aadhar Number is required",
  OBJECTIVE_IS_REQUIRED: "Objective is required",
  FILE_IS_REQUIRED:"File is required",
  FILE_IS_INVALID:"Please select a valid file.",
  MATRICULATION_SUBJECTS_IS_REQUIRED: "10th Subjects is required",
  MATRICULATION_SCHOOL_NAME_IS_REQUIRED: "10th School name is required",
  MATRICULATION_PASSING_YEAR_IS_REQUIRED: "10th passing year is required",
  MATRICULATION_PERCENTAGE_IS_REQUIRED: "10th Percentage is required",
  MATRICULATION_AGGREGATE_IS_REQUIRED: "10th Aggregate is required",
  INTERMEDIATE_SUBJECTS_IS_REQUIRED: "12th Subjects is required",
  INTERMEDIATE_SCHOOL_NAME_IS_REQUIRED: "12th School name is required",
  INTERMEDIATE_PASSING_YEAR_IS_REQUIRED: "12th passing year is required",
  INTERMEDIATE_PERCENTAGE_IS_REQUIRED: "12th Percentage is required",
  INTERMEDIATE_AGGREGATE_IS_REQUIRED: "12th Aggregate is required",
  CORRECT_DOB_REQUIRED:"Enter Correct DOB",
  PASSPORT_NUMBER_IS_REQUIRED:"PassPort Number is required",
  INVALID_PASSPORT:"Passport Number is Invalid",
  IS_PASSPORT_IS_REQUIRED:"Passport selection is required",
  HOBBY_IS_REQUIRED:"Hobby is required",
  DIPLOMA_SUBJECTS_IS_REQUIRED: "Diploma Subjects is required",
  DIPLOMA_COLLAGE_NAME_IS_REQUIRED: "Diploma Collage name is required",
  DIPLOMA_PASSING_YEAR_IS_REQUIRED: "Diploma passing year is required",
  DIPLOMA_PERCENTAGE_IS_REQUIRED: "Diploma Percentage/Cgpa is required",
  DIPLOMA_AGGREGATE_IS_REQUIRED: "Diploma Aggregate is required",
  GRADUATION_SUBJECTS_IS_REQUIRED: "Graduation Subjects is required",
  GRADUATION_COLLAGE_NAME_IS_REQUIRED: "Graduation Collage name is required",
  GRADUATION_PASSING_YEAR_IS_REQUIRED: "Graduation passing year is required",
  GRADUATION_PERCENTAGE_IS_REQUIRED: "Graduation CGPA/Percentage/Cgpa is required",
  GRADUATION_AGGREGATE_IS_REQUIRED: "Graduation Aggregate is required",
  POSTGRADUATION_SUBJECTS_IS_REQUIRED: "PostGraduation Subjects is required",
  POSTGRADUATION_COLLAGE_NAME_IS_REQUIRED: "PostGraduation Collage name is required",
  POSTGRADUATION_PASSING_YEAR_IS_REQUIRED: "PostGraduation passing year is required",
  POSTGRADUATION_PERCENTAGE_IS_REQUIRED: "PostGraduation Percentage/Cgpa is required",
  POSTGRADUATION_AGGREGATE_IS_REQUIRED: "PostGraduation Aggregate is required",
  MPHILL_SUBJECTS_IS_REQUIRED: "Mphill Subjects is required",
  MPHILL_COLLAGE_NAME_IS_REQUIRED: "Mphill Collage name is required",
  MPHILL_PASSING_YEAR_IS_REQUIRED: "Mphill passing year is required",
  MPHILL_PERCENTAGE_IS_REQUIRED: "Mphill Percentage/Cgpa is required",
  MPHILL_AGGREGATE_IS_REQUIRED: "Mphill Aggregate is required",
  PHD_SUBJECTS_IS_REQUIRED: "Phd Subjects is required",
  PHD_COLLAGE_NAME_IS_REQUIRED: "Phd Collage name is required",
  PHD_PASSING_YEAR_IS_REQUIRED: "Phd passing year is required",
  PHD_PERCENTAGE_IS_REQUIRED: "Phd Percentage/Cgpa is required",
  PHD_AGGREGATE_IS_REQUIRED: "Phd Aggregate is required",
  TO_DATE_IS_REQUIRED:"To Date is required",
  FROM_DATE_IS_REQUIRED:"From Date is required",
  PROJECT_IS_REQUIRED:"Project Url is required",
  PROJECT_DESC_IS_REQUIRED:"Project Description is required",
  SKILL_IS_REQUIRED:"Skill is required",
  HEADING_IS_REQUIRED:"Heading is required",
  IMAGE_IS_REQUIRED:"Image is required",
  USER_TYPE_IS_REQUIRED:"User type is required",
  ABOUT_COMPANY_IS_REQUIRED:"About Company is required",
  WE_NEED_MORE_INFORMATION_ABOUT_YOUR_COMPANY:"We Need More Information About Your Company",
  TITLE_IS_REQUIRED:"Title is required",
  SELECT_IMAGE:"Please select image",
  IMAGE_NOT_FOUND:"No image found in this data",
  DESCRIPTION_IS_REQUIRED:"Description is required",
  COUNTRY_IS_REQUIRED:"Country is required",
  // REGION_IS_REQUIRED:"Region is required",
  REGISTERED_REGION_IS_REQUIRED:"Registered Region is Required",
  OLD_PASSWORD_REQUIRED:"Old Password is required",
  NEW_PASSWORD_REQUIRED:"New Password is required",
  CONFIRM_PASSWORD:"Confirm Password is required",
  JOBCATEGORY_REQUIRED:"Job Category required",
  LEVEL_IS_REQUIRED:"Level is required.",
  COURSE_NAME_REQUIRED:"Course Name is required",
  BRANCH_NAME_REQUIRED:"Branch Name is required",
  DEPARTMENT:"Department is required",
  COURSE:"Course is required",
  BRANCH:"Branch is required",
  ROLE:"Role is required",
  TEST_NAME:"Test Name is required",
  TEST_DURATION:"Test Duration is required",
  PASSING_PERCENTAGE:"Passing Percentage is required",
  QUESTION_TYPE:"Question Type is required",
  ROLE_NAME_REQUIRED:"Role Name is required",
  CORPORATE_NAME_REQUIRED:"Corporate Name is required",
  DEPARTMENT_REQUIRED:"Department Name is required",
  NUMBER_OF_JOB:"Number of job required",
  CTC_REQUIRED:"CTC type required",
  SALARY_AMOUNT_REQUIRED:"Salary amount is required",
  QUESTION_IS_REQUIRED:"Question is required",
  QUESTION_TYPE_IS_REQUIRED:"Question Type is required",
  OPTION_IS_REQUIRED:"Option is required",
  CORRECT_ANSWER:"Correct Answer is required",
  JOB_CATEGORY_REQUIRED:"Job Category required",
  TOPIC_IS_REQUIRED:"Topic is required",
  START_DATE_AND_TIME_IS_REQUIRED:"Start date and time is required.",
  DURATION_IS_REQURED:"Duration is required.",
  JOB_DESCRIPTION_REQUIRED:"Job Description is required",
  TERMS_CONDITION_AND_REQUIRED:"Terms and Condition and required",
  ALLOWED_PASSING_YEAR:"Allowed passing year is reuired",
  NUMBER_OF_STUDENT:"Number of student required",
  DESIGNATION_IS_REQUIRED:"Designation is required",
  INTERVIEWER_COUNT_REQUIRED:"Interviewer Count is required",
  DATE_OF_CAMPUS_DRIVE_IS_REQUIRED:"Date of campus drive is required.",
  PROCESS_GAP_IS_REQUIRED:"Process gap is required.",
  INDIVIDUAL_INTERVIEW_TIMELINE_IS_REQUIRED:"Individual interview timeline is required.",
  RATING_IS_REQUIRED:"Rating is required",
  PARAMETER_IS_REQUIRED:"Parameter is required",
  MATRICULATION_MARKS_TYPE_REQUIRED: "10th marksType is required",
  INTERMEDIATE_MARKS_TYPE_IS_REQUIRED: "12th marksType is required",
  SELECT_MARKS_TYPE:"Select Marks type",
  SKILLS_IS_REQUIRED:"Enter Skills",
  ENTER_NUMERIC: "Enter Numeric Value",
  SIGNATURE_IS_REQUIRED:"Signature is required",
  PERSENTATION_DATE_TIME_IS_REQUIRED:"Presentation date time is required.",
  PANEL_IS_REQURED:"Panel is requred.",
  ASSESMENT_IS_REQURED:"Assesment is requred.",
  HIRINGPROCESS_IS_REQURED:"Hiring process is requred.",
  MOU_NOT_FOUND:"MOU not found.",
  AFFILITED_BY_REQUIRED:"Select Affilited By",
  ACCEPT_TEARMS_AND_CONDITION:"Teams & condition is required.",
  CERTIFICATE_IMAGE_REQUIRED:"Certificate image is required.",
  CIN_NUMBER:"CIN Number is required",
  PHONE_STD_CODE:"Std Code is required",
    GST_CERTIFICATE_IS_REQUIRED : "Gst certificate is Required",
  PAN_CERTIFICATE_IS_REQUIRED : "Pan certificate is Required",
  LAST_QUALIFICATION_IS_REQUIRED : "Last Qualification is Required",
  NEXT_QUALIFICATION_IS_REQUIRED : "Next Qualification is Required"

};

export const COMMON = {
  SUBMIT: "Submit",
  SIGN_UP: "Sign up",
  LOGIN: "Login",
  BACK_TO_LOGIN: "Back To Login",
  NEXT: "Next",
  SKIP: "Skip",
  PAY_NOW: "Pay Now",
};

export const POSTION = {
  TOP_RIGHT: "top-right",
  TOP_LEFT: "top-left",
};

export const PLACEHOLDER = {
  EMAIL: "Email",
  PASSWORD: "Password",
};

export const INPUTTYPE = {
  TEXT: "text",
  PASSWORD: "password",
};
export const SERVER = process.env.SERVER;

export const API_BASE_URL_SERVER = process.env.API_BASE_URL_SERVER;
// export const API_BASE_URL_SERVER = "https://globalaspirants.com/api";
//  export const API_BASE_URL_SERVER = "http://10.5.48.63:8080/api";
// export const API_BASE_URL_SERVER = "http://10.5.48.97:8080/api";
 export const API_BASE_URL_LOCAL = "http://localhost:8080/api";

export const COMMON_ENDPOINTS = {
  COUNTRIES:"/countries",
  REGION: "/rigions",
  STATES: "/states",
  DISTRICTS: "/districts",
  DELETE_REGION_BY_ID:"/rigions/deleteRigionById"
};

export const API_END_POINTS_USER = {
  RIGION: "/rigions",
  STATE: "/states/regionId",
  DISTRICT: "/districts",
  SIGNATURE: "/user/upload/signature",
  PROFILE_PIC: "/user/upload/profile",
  LOGO: "/user/upload/logo",
  AFFILIATED_CERTIFICATE:"/user/upload/affiliatedcertificate",
  DOCUMENT:"/user/upload/document",
  CAMPUS_DATA: "/campus",
  CAMPUS: "/campus",
  CORPORATES: "/corporates",
  REGIONS:"/rigions",
  STUDENT:"/students",
  COUNTRIES:"/countries",
  EMAIL_API_VALIDATION:"/user/validateData",
  VALIDATE_GST:"/user/validateGst",
  GET_ACTIVE_DEPARTMENTS:"/departments/getActiveDepartments",
  PROFILE_UPDATE: "/user/updateProfile",
  GET_CAMPUS_BY_ID:"/campus/getCampusById",
  GET_ACTIVE_COURSES:"/courses/getActiveCourses",
  GET_ACTIVE_BRANCH:"/branches/getActiveBranches",
  DIRECT_LOGIN_FOR_SUPERADMIN:"/user/directLoginForSuperAdmin",  
  GET_STUDENT_BY_ID:"/students/getStudentById",
    GET_QALIFICATION_TYPE_DATA: "/departments/getQualificationType",
  GET_ACTIVE_DEPARTMENTS_BY_ID: "/departments/getActiveDepartmentById",
  UPDATE_STUDENT_BY_ID: "/students/updateStudentById",
}
 

export const API_END_POINTS_CAMPUS = {
  CAMPUS_DATA: "/campus",
  CAMPUS_DATA_SEARCH: "/campus/search",
};

export const API_END_POINTS_STUDENTS = {
  STUDENTS_DATA: "/students",
  STUDENTS_DATA_SEARCH: "/students/search",
};

export const API_END_POINTS_CORPORATES = {
  CORPORATES_DATA: "/corporates",
  CORPORATES_DATA_SEARCH: "/corporates/search",
};

export const API_END_POINTS_DASHBOARD = {
  DASHBOARD_DATA: "/dashboard/getTotalRegistration",
};

export const COOKIE_NAME = {
  token: "x-auth-token",
  userType: "userType",
  name: "name",
};

export const filterFormData = {
  regionId: "",
  stateId: "",
  districtId: "",
};


export const campusFormData = {
  region: "",
  state: "",
  district: "",
  city: "",
  pincode: "",
  address: "",
  faxNumber: "",
  campusLogoUrl: "",
  tpoName: "",
  tpoEmail: "",
  tpoMobile: "",
  deanName: "",
  deanEmail: "",
  deanMobile: "",
  webUrl: "",
  digitalSignUrl: "",
};

export const corporateFormData = {
  fullName: "",
  email: "",
  mobile: "",
  userType: ROLES.CORPORATE,
  corporateName: "",
  corporateOfficeAddress: "",
  registeredOfficeAddress: "",
  region: "",
  state: "",
  district: "",
  city: "",
  pincode: "",
  faxNumber: "",
  corporatePhoneNumber: "",
  corporateEmail: "",
  corporateMobileNumber: "",
  corporateLogoUrl: "",
  hrHeadName: "",
  hrHeadEmail: "",
  hrHeadMobile: "",
  founderName: "",
  founderEmail: "",
  founderMobile: "",
  panNumber: "",
  gstNumber: "",
  yearOfEstablishment: "",
  industry: "",
  groupTurnover: "",
  totalEmployeeStrength: "",
  webUrl: "",
  digitalSignUrl: "",
  tanNumber: "",
  nameOfTheGroupCompanies: "",
};

export const studentFormData = {
  campusId: "",
  fullName: "",
  email: "",
  mobile: "",
  userType: "",
  fatherName: "",
  motherName: "",
  panNumber: "",
  aadharNumber: "",
  dateOfBirth: "",
  region: "",
  state: "",
  district: "",
  city: "",
  pincode: "",
  presentAddress: "",
  permanentAddress: "",
  emergencyContactNumber: "",
  alternetMobileNumber: "",
  phoneNumber: "",
  passport: "",
  nationality: "",
  religion: "",
  objective: "",
  hobby: "",
  awardsAndAchievements: "",
  pictureUrl: "",
  intenrship: "",
  project: "",
  matriculationSubject: "",
  matriculationSchool: "",
  matriculationPassingYear: "",
  matriculationCgpaPercentage: "",
  matriculationAggregate: "",
  intermediateSubject: "",
  intermediateSchool: "",
  intermediatePassingYear: "",
  intermediateCgpaPercentage: "",
  intermediatenAggregate: "",
  diplomaSubject: "",
  diplomaCollege: "",
  diplomaPassingYear: "",
  diplomaCgpaPercentage: "",
  diplomaAggregate: "",
  graduationSpecialized: "",
  graduationCollege: "",
  graduationPassingYear: "",
  graduationCgpaPercentage: "",
  graduationAggregate: "",
  postGraduationSpecialized: "",
  postGraduationCollege: "",
  postGraduationPassingYear: "",
  postGraduationCgpaPercentage: "",
  postGraduationAggregate: "",
  mphillSpecialized: "",
  mphillCollege: "",
  mphillPassingYear: "",
  mphillCgpaPercentage: "",
  mphillAggregate: "",
  phdSpecialized: "",
  phdCollege: "",
  phdPassingYear: "",
  phdCgpaPercentage: "",
  phdAggregate: "",
};


export const matriculationSubjectList = 
  [
    { value: 'hindi', label: 'Hindi' },
    { value: 'english', label: 'English' },
    { value: 'mathematics', label: 'Mathematics' },
    { value: 'science', label: 'Science' },
    { value: 'social-science', label: 'Social science' },
    { value: 'computer', label: 'Computer' },
    { value: 'drawing', label: 'Drawing' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'business_study', label: 'Business Study' },
    { value: 'economics', label: 'Economics' },
    { value: 'sanskrit', label: 'Sanskrit' },
    { value: 'punjabi', label: 'Punjabi' },
    { value: 'telugu', label: 'Telugu' },
    { value: 'urdu', label: 'Urdu' },
    { value: 'assamese', label: 'Assamese' },
    { value: 'nepali', label: 'Nepali' },
    { value: 'bengali', label: 'Bengali' },
    { value: 'marathi', label: 'Marathi' },
    { value: 'gujrati', label: 'Gujrati' },
    { value: 'kannada', label: 'Kannada' },
    { value: 'malayalam', label: 'Malayalam' },
    { value: 'oriya', label: 'Oriya' },
  ];


  export const twelfthScienceSubjectList  = [
    { value: 'physics', label: 'Physics' },
    { value: 'chemistry', label: 'Chemistry' },
    { value: 'mathematics', label: 'Mathematics' },
    { value: 'biology', label: 'Biology' },
    { value: 'english', label: 'English' },
    { value: 'computer-science', label: 'Computer Science' },
    { value: 'informatics-practices', label: 'Informatics Practices' },
    { value: 'physical-education', label: 'Physical Education' },
  ];

  export const streamList = [
    {value: 'engineering' , label: (capitalizeFirstLetter('engineering'))},
    {value: 'management' , label: (capitalizeFirstLetter('management'))},
  ]