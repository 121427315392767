import '@/styles/globals.css';
// import '../styles/headingmodule.css';
import { Inter } from 'next/font/google';
import Head from 'next/head';
import Layout from '@/components/layout';
export const metadata= {
  title: 'Easy Campus',
  description: 'Generated by create next app',
}

import React, { useEffect,useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { showToast } from '@/utils/showtoast';
import { COLOR_CODE } from "@/utils/colorconstant";
import { POSTION, SERVER_TYPE } from '@/utils/constant';
import { checkUserOnlineSatus } from '@/components/action/easycampus';
import { useRouter } from "next/router";
import PreLoginLayout from '@/components/layout/prelogin';

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
});

export default function App({ Component, pageProps }) {

  const [token, setToken] = useState("");
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState('');
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [pushNotification, setPushNotification] =
    useState('');

    const router = useRouter();
    useEffect(() => {

      if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
        import('@fvilers/disable-react-devtools')
          .then((module) => {
            const disableReactDevTools = module.disableReactDevTools || module.default;
            if (typeof disableReactDevTools === 'function') {
              disableReactDevTools();
              console.log("disableReactDevTools function called");
            } else {
              console.log("disableReactDevTools is not a function");
            }
          })
          .catch((err) => {
            console.error("Failed to load disableReactDevTools:", err);
          });

          console.log = () => {};
          console.warn = () => {};
          console.error = () => {};
      }
    
    }, []);

    // if (process.env.SERVER === SERVER_TYPE.PRODUCTION) {
    //   console.log = () => { };
    //   console.warn = () => { };
    //   console.error = () => { };
    // }

    const disableShortcuts = () => {
      useEffect(() => {
        // Disable right-click
        const handleContextMenu = (event) => {
          event.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextMenu);
    
        // Disable certain keyboard shortcuts
        const handleKeyDown = (event) => {
          if (
            (event.ctrlKey && event.key === 'u') ||  // Ctrl+U
            (event.ctrlKey && event.key === 'U') ||  // Ctrl+U
            (event.ctrlKey && event.shiftKey && event.key === 'I') ||  // Ctrl+Shift+I
            (event.ctrlKey && event.shiftKey && event.key === 'i') ||  // Ctrl+Shift+i
            (event.key === 'F12') ||  // F12
            (event.ctrlKey && event.shiftKey && event.key === 'C') ||  // Ctrl+Shift+C
            (event.ctrlKey && event.shiftKey && event.key === 'J') ||  // Ctrl+Shift+J
            (event.ctrlKey && event.shiftKey && event.key === 'c') ||  // Ctrl+Shift+c
            (event.ctrlKey && event.shiftKey && event.key === 'j') ||  // Ctrl+Shift+j
            (event.ctrlKey && event.key === 's') ||  // Ctrl+S
            (event.ctrlKey && event.key === 'p') ||  // Ctrl+P
            (event.metaKey && event.key === 'u') ||  // Cmd+U (Mac)
            (event.metaKey && event.shiftKey && event.key === 'I') ||  // Cmd+Shift+I (Mac)
            (event.metaKey && event.shiftKey && event.key === 'C') ||  // Cmd+Shift+C (Mac)
            (event.metaKey && event.shiftKey && event.key === 'J') ||  // Cmd+Shift+J (Mac)
            (event.metaKey && event.key === 's') ||  // Cmd+S (Mac)
            (event.metaKey && event.key === 'p') ||  // Cmd+P (Mac)
            (event.altKey && event.key === 'u') ||  // Option+U (Mac)
            (event.altKey && event.shiftKey && event.key === 'I') ||  // Option+Shift+I (Mac)
            (event.altKey && event.shiftKey && event.key === 'C') ||  // Option+Shift+C (Mac)
            (event.altKey && event.shiftKey && event.key === 'J') ||  // Option+Shift+J (Mac)
            (event.altKey && event.key === 's') ||  // Option+S (Mac)
            (event.altKey && event.key === 'p')  // Option+P (Mac)
            
          ) {
            event.preventDefault();
          }
        };
        document.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
    };
  
    if(process.env.SERVER===SERVER_TYPE.PRODUCTION)disableShortcuts();

  return (
    <>
    <Head>
        <title>EasyCampus</title>
        <link rel="icon" href="/easycampus.png" />
      </Head>
      {router.route==="/" ?  <PreLoginLayout style={{ fontFamily: 'Chivo, sans-serif' }} className={`${inter.variable} font-sans`}>
      
        <Component {...pageProps} />
        <ToastContainer />
      </PreLoginLayout> : <Layout style={{ fontFamily: 'Chivo, sans-serif' }} className={`${inter.variable} font-sans`}>
  
     
        <Component {...pageProps} />
        <ToastContainer />
  
        </Layout>}
    </>
  );
}
