import React from "react";
import Sidebar from "./sidebar";
// import Topbar from "./topbar";
import { Container } from "reactstrap";
import { useRouter } from "next/router";

const Layout = ({ children }) => {





  const router = useRouter();
  // console.log("Layout",router.route);
  // const allowedPaths = ["/", "/registration", "/profileunderreview", "/corporateprofileupdate", "/campusprofileupdate", "/payment", "/otpsubmission", "/profileupdate/student","/changepassword","/corporates/corporateprofileupdate","/campus/campusprofileupdate"];
  // const shouldRenderSidebar = !allowedPaths.includes(router.pathname);
  // const shouldRenderTopbar = !allowedPaths.includes(router.pathname);

  // const renderSidebar = shouldRenderSidebar && (
  //   <Sidebar />
  // );

  // const renderTopbar = shouldRenderTopbar && (
  //   <Topbar />
  // );

  return (
    <>
      {/* <div className="flex mt-[4.7rem] md:mt-0">
        {renderSidebar}
        <div className="content">
          {renderTopbar} */}
          <Container className="wrapper" fluid>
            <div>{children}</div>
          </Container>
        {/* </div>
      </div> */}
    </>
  );
}

export default Layout;

