export const getHeaders = (additionalHeaders = {}) => {
    const localString = sessionStorage.getItem("x-auth-token");
  
    if (!localString) {
      throw new Error("Token not found, please login again.");
    }
  
    return {
      "x-auth-token": `${localString}` || process.env.TOKEN,
      ...additionalHeaders,
    };
  };
  