import axios from "axios";
// import jwt from 'jsonwebtoken';
import {
  ACTION,
  API_BASE_URL_LOCAL,
  API_BASE_URL_SERVER,
  API_END_POINTS_USER,
  SERVER,
} from "@/utils/constant";
import { decodeParams, encodeParams } from "@/utils/encodedecode";
import { getHeaders } from "./tokenupdateHeader";

let baseURL =
  SERVER === "development" ? API_BASE_URL_SERVER : API_BASE_URL_LOCAL;

// const getHeaders = (additionalHeaders = {}) => {
//   // const tokenData = sessionStorage.getItem("loginData");
//   // console.log("tokenData",tokenData);
//   // if (!tokenData) {
//   //   throw new Error("Please login again.");
//   // }
//   //const parsedTokenData = JSON.parse(tokenData);

//   const token = process.env.TOKEN;
//   if (!token) {
//     throw new Error("Please login again.");
//   }

//   return {
//     "x-auth-token": `${token}`,
//   };
// };

export const regionsDataApi = async () => {
  try {
    let headers = getHeaders();

    const regionsData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.RIGION}`,
      {
        headers,
      }
    );
    return decodeParams(regionsData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const stateDataApi = async (rigionId) => {
  try {
    const headers = getHeaders();

    const stateData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.STATE}/${rigionId}`,
      {
        headers,
      }
    );
    return decodeParams(stateData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const districtDataApi = async (data,stateId) => {
  try {
    const headers = getHeaders();
    let params = {data:encodeParams(data)};
    const districtData = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DISTRICT}/${stateId}`,
      {
        headers,
        params
      }
    );
    return decodeParams(districtData?.data);
  } catch (error) {
    console.error(error);
  }
};

export const signatureUploadApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const signatureFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.SIGNATURE}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(signatureFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const profilePicApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const profilePicFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_PIC}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(profilePicFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const logoFileApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const logoFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.LOGO}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(logoFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const documentFileApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const logoFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DOCUMENT}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(logoFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const affiliatedCertificateFileApi = async (formData) => {
  try {
    const headers = getHeaders({
      "Content-Type": "multipart/form-data",
    });

    const logoFile = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.AFFILIATED_CERTIFICATE}`,
      formData,
      {
        headers,
      }
    );
    return decodeParams(logoFile?.data);
  } catch (error) {
    console.error(error);
  }
};

export const addCampusApi = async (action, reqBody) => {
  try {
    const headers = getHeaders();
    let reqData = {data:encodeParams(reqBody)};
    let response;
    if (action.mode === ACTION.EDIT) {
      response = await axios.patch(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_CAMPUS_BY_ID}/${action.id}`,
        reqData,
        {
          headers,
        }
      );
    } else if (action.mode === ACTION.ADD) {
      response = await axios.post(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CAMPUS}`,
        reqData,
        {
          headers,
        }
      );
    }
    // console.log("response:",response);
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addCorporateApi = async (action, reqBody) => {
  try {
    const headers = getHeaders();
    let reqData = {data:encodeParams(reqBody)};
    let response;
    if (action.mode === ACTION.EDIT) {
      response = await axios.patch(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_CORPORATE_BY_ID}/${action.id}`,
        reqData,
        {
          headers,
        }
      );
    } else if (action.mode === ACTION.ADD) {
      response = await axios.post(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.CORPORATES}`,
        reqData,
        {
          headers,
        }
      );
    }
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const addStudentApi = async (action, reqBody) => {
  try {
    const headers = getHeaders();
    let reqData = {data:encodeParams(reqBody)};
    let response;
    if (action.mode === ACTION.EDIT) {
      // console.log("action.mod",action.mode);
      response = await axios.patch(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.UPDATE_STUDENT_BY_ID}/${action.id}`,
        reqData,
        {
          headers,
        }
      );
    } else if (action.mode === ACTION.ADD) {
      response = await axios.post(
        `${API_BASE_URL_SERVER}${API_END_POINTS_USER.STUDENT}`,
        reqData,
        {
          headers,
        }
      );
      // console.log("add response",response);
    }
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const dataValidationApi = async (targetData, targetColumn) => {
  try {
    let reqBody = { [targetColumn]: targetData };
    let reqData = {data:encodeParams(reqBody)};
    const headers = getHeaders();
    let response = await axios.patch(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.EMAIL_API_VALIDATION}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

export const validateGstApi = async (gst) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.VALIDATE_GST}/${gst}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getActiveDepartmentsApi = async (data) => {
  try {
    let params = {data:encodeParams(data)};
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_DEPARTMENTS}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getCampusByIdDataApi = async (id,) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_CAMPUS_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getActiveBranchApi = async (data) => {
  try {
    let params = {data:encodeParams(data)};
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_BRANCH}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getActiveCourseApi = async (data) => {
  try {
    let params = {data:encodeParams(data)};
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_ACTIVE_COURSES}`,
      {
        headers,
        params
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
  }
};

export const getCommonApi = async (action, data) => {
  try {
    let params = {};
    if (data) {
        params.data = data;
    }
      if (action.searchData) {
        params.search = action.searchData;
      }
    
    // console.log("action", action);
    // console.log("paramsData", params);
    // console.log("data", data);
    const headers = getHeaders();
    // let params = paramsData;
    let paramsData = { data: encodeParams(params) };
    let response = await axios.get(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}`,
      {
        headers,
        params: paramsData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getByIdCommonApi = async (action) => {
  try {
    const headers = getHeaders();
    let permasData = { data: encodeParams(action.data) };
    let response = await axios.get(
      `${API_BASE_URL_SERVER}${action.apiEndPoint}/${action.id}`,
      {
        headers,
        params:permasData
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDirectLoginForSuperAdminApi = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.DIRECT_LOGIN_FOR_SUPERADMIN}`
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error("Error in getDirectLoginForSuperAdminApi:", error);
  }
};

export const getStudentByIdDataApi = async (id) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.GET_STUDENT_BY_ID}/${id}`,
      {
        headers,
      }
    );
    return decodeParams(response?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const studentProfileUpdateApi = async (reqBody = {}) => {
  try {
    let reqData = { data: encodeParams(reqBody) };
    const headers = getHeaders();
    const profileUpdateResponse = await axios.post(
      `${API_BASE_URL_SERVER}${API_END_POINTS_USER.PROFILE_UPDATE}`,
      reqData,
      {
        headers,
      }
    );
    return decodeParams(profileUpdateResponse?.data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};