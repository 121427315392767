import React from "react";

import { Container } from "reactstrap";
import { useRouter } from "next/router";

const PreLoginLayout = ({ children }) => {

    // console.log("Checking data ",children);

  const router = useRouter();
//   const allowedPaths = ["/", "/registration", "/profileunderreview", "/corporateprofileupdate", "/campusprofileupdate", "/payment", "/otpsubmission", "/profileupdate/student","/changepassword","/corporates/corporateprofileupdate","/campus/campusprofileupdate"];
//   const shouldRenderSidebar = !allowedPaths.includes(router.pathname);
//   const shouldRenderTopbar = !allowedPaths.includes(router.pathname);

//   const renderSidebar = shouldRenderSidebar && (
//     <Sidebar />
//   );

//   const renderTopbar = shouldRenderTopbar && (
//     <Topbar />
//   );

  return (
    <>
      <div className="">
        <div className="">
          <Container className="" fluid>
            <div>{children}</div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PreLoginLayout;

