import moment from 'moment-timezone';

export function capitalizeFirstLetter(inputString) {
  // if (typeof inputString !== "string" || inputString.length === 0) {
  //   return inputString;
  // }

  // return inputString.charAt(0).toUpperCase() + inputString.slice(1);

  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  const words = inputString.split(' ');

  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.substring(1);
    }
  }).join(" ");

//   words.map((word) => { 
//     return word[0].toUpperCase() + word.substring(1); 
// }).join(" ");

  return capitalizedWords;
}

export function capitalizeFirstLetterAfterDot(inputString) {
  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  const words = inputString.split('. ');

  const capitalizedWords = words.map((sentence) => {
    if (sentence.length > 0) {
      const firstLetter = sentence[0].toUpperCase();
      const restOfSentence = sentence.substring(1).toLowerCase();
      return firstLetter + restOfSentence;
    }
  }).join('. ');
  
  return capitalizedWords;
}



export function capitalizeAllLetters(inputString) {
  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  return inputString.toUpperCase();
}

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate();
  const month = dateObject.toLocaleString("default", { month: "short" });
  const year = dateObject.getFullYear().toString().slice(-2);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes} ${period}`;
};

export function getMonthName(month) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month - 1];
}

export const maskEmail = (email) => {
  const atIndex = email?.indexOf("@");
  const dotIndex = email?.lastIndexOf(".");
  const username = email?.slice(0, 2) + "*".repeat(Math.max(0, atIndex - 2));
  const domain =
    "*".repeat(Math.max(0, dotIndex - atIndex - 2)) + email?.slice(dotIndex);
  const maskedEmail = username + domain;
  return maskedEmail;
};

export const maskMobile = (mobile) => {
  if (!mobile) {
    return "";
  }
  const maskMobile =
    mobile.slice(0, 2) +
    "*".repeat(Math.max(0, mobile.length - 4)) +
    mobile.slice(-2);

  return maskMobile;
};

export const maskPanNumber = (pannumber) => {
  if (!pannumber) {
    return "";
  }
  const maskPanNumber =
    pannumber.slice(0, 2) +
    "*".repeat(Math.max(0, pannumber.length - 4)) +
    pannumber.slice(-2);

  return maskPanNumber;
};

export const maskTanNumber = (tannumber) => {
  if (!tannumber) {
    return "";
  }
  const maskTanNumber =
    tannumber.slice(0, 2) +
    "*".repeat(Math.max(0, tannumber.length - 4)) +
    tannumber.slice(-2);

  return maskTanNumber;
};

export const maskGstNumber = (gstnumber) => {
  if (!gstnumber) {
    return "";
  }
  const maskGstNumber =
    gstnumber.slice(0, 2) +
    "*".repeat(Math.max(0, gstnumber.length - 4)) +
    gstnumber.slice(-2);
  return maskGstNumber;
};

export const maskfaxNumber = (faxnumber) => {
  if (!faxnumber) {
    return "";
  }

  const maskedFaxNumber =
    faxnumber.slice(0, 2) +
    "*".repeat(Math.max(0, faxnumber.length - 4)) +
    faxnumber.slice(-2);

  return maskedFaxNumber;
};

export const convertTimestampToDateTime = (timestampMs) => {
  const timestampSec = timestampMs / 1000;
  const dateObject = new Date(timestampSec);
  const formattedDateTime = dateObject.toUTCString();
  return formattedDateTime;
}

export const dateFormatForUser = (dateString) => {


  const indianTime = moment.utc(dateString).tz("Asia/Kolkata");


  const day1 = indianTime.format('DD');
  const month1 = indianTime.format('MMM');
  const year1 = indianTime.format('YY');
  const hours1 = indianTime.format('hh');
  const minutes1 = indianTime.format('mm');
  const period1 = indianTime.format('A');

console.log("UTC Time:", dateString);
console.log("Indian Time:", indianTime.format("YYYY-MM-DD hh:mm A"));
  return `${day1}-${month1}-${year1} ${hours1}:${minutes1} ${period1}`;
};

export const decodeHTML = (html) => {
  if (typeof html !== 'string' || html.trim() === '') {
    return ''; 
  }

  const decodedText = html.replace(/&#(\d+);|&([^;\s]+);/g, (match, dec, named) => {
    if (dec) {
      return String.fromCharCode(dec);
    } else {
      const map = {
        'amp': '&',
        'lt': '<',
        'gt': '>',
        'quot': '"',
        'apos': "'",
        // Add more entities as needed
      };
      return map[named] || match;
    }
  });
  return decodedText;
};








export const getFirstTenCharacters = (str) => {
  return str.slice(0, 10);
}
