import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ROLES, ROUTE_STRING } from "@/utils/constant";
import SidebarTab from "./sidebarTab";
import Image from "next/image";
import logo from "../../../public/assets/svg/favcon-4.svg";
import lglogo from "../../../public/assets/images/easycampus-logo.png";
import MobilesideTab from "./mobilesideTab";
import Demopopup from "./demopopup";
import SvgIcon from "@/components/common/svgicon";

const Sidebar = ({ redirectionUrl, tabName }) => {
  const currentRoute = usePathname("");
  const [userData, setUserData] = useState("");
  const settingsDropdownRef = useRef(null);
  const reportsDropdownRef = useRef(null);
  const invitationmgmtRef = useRef(null);
  const testmgmtRef = useRef(null);
  const campusplaningRef = useRef(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isTestDropdownOpen, setTestDropdownOpen] = useState(false);
  const [invitationDropdownOpen, setInvitationDropdownOpen] = useState(false);
  const [campusplaningDropdownOpen, setCampusPlaningDropdownOpen] =
    useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [reportDropdown, setReportDropdown] = useState(null);

  useEffect(() => {
    const loginDataString = sessionStorage.getItem("loginData");
    if (loginDataString) {
      const loginData = JSON.parse(loginDataString);
      setUserData(loginData.data);
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      settingsDropdownRef.current &&
      !settingsDropdownRef.current.contains(event.target)
    ) {
      setDropdownOpen(null);
    } else if (
      reportsDropdownRef.current &&
      !reportsDropdownRef.current.contains(event.target)
    ) {
      setDropdownReport(null);
    } else if (
      invitationmgmtRef.current &&
      !invitationmgmtRef.current.contains(event.target)
    ) {
      setInvitationDropdownOpen(null);
    } else if (
      campusplaningRef.current &&
      !campusplaningRef.current.contains(event.target)
    ) {
      setCampusPlaningDropdownOpen(null);
    } else if (
      testmgmtRef.current &&
      !testmgmtRef.current.contains(event.target)
    ) {
      setTestDropdownOpen(null);
    }
  };

  //  const [sidebarWidth, setSidebarWidth] = useState('16rem');
  const toggleSidebar = () => {
    //  setSidebarWidth(sidebarWidth === '16rem' ? '20rem' : '16rem');
    setSidebarOpen(!isSidebarOpen);
  };

  const closeMobileSidebar = () => {
    // console.log("Hii");
    //setSidebarOpen(false);
  };

  const [isSidebarOpen1, setIsSidebarOpen1] = useState(true);
  // const [sidebarWidth, setSidebarWidth] = useState('16rem');

  const toggleSidebar1 = () => {
    // setSidebarWidth(sidebarWidth === '16rem' ? '20rem' : '16rem');
    setIsSidebarOpen1(!isSidebarOpen1);
  };

  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const [activeSubMenu, setActiveSubMenu] = useState(null);

  // const toggleDropdown = (subMenu) => {
  //   if (subMenu === activeSubMenu) {
  //     setDropdownOpen(!isDropdownOpen);
  //     setActiveSubMenu(null);
  //   } else {
  //     setDropdownOpen(true);
  //     setActiveSubMenu(subMenu);
  //   }
  // };

  const setDropdownReport = (dropdownReport) => {
    setReportDropdown(
      reportDropdown === dropdownReport ? null : dropdownReport
    );
  };

  const setDropdownInvitation = () => {
    setInvitationDropdownOpen(!invitationDropdownOpen);
    setActiveSubMenu(null);
  };
  const setTestDropdown = () => {
    setTestDropdownOpen(!isTestDropdownOpen);
    setActiveSubMenu(null);
  };

  const setCampusPlaningDropDown = () => {
    setCampusPlaningDropdownOpen(!campusplaningDropdownOpen);
    setActiveSubMenu(null);
  };

  const toggleMainDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    setActiveSubMenu(null);
  };

  const toggleSubMenu = (subMenu) => {
    setActiveSubMenu((prevSubMenu) =>
      prevSubMenu === subMenu ? null : subMenu
    );
  };

  return (
    <>
      <div className="md:hidden lg:hidden bg-[#1e336a] fixed z-50   top-0 right-0 md:left-0  md:overflow-auto  w-full ">
        <Image
          src={logo}
          className=" w-20 m-1 md:m-auto md:hidden"
          alt="logo"
        />
        <button
          className="lg:hidden fixed  md:hidden top-5 right-5 p-2  text-white rounded"
          onClick={toggleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            icon-name="bar-chart-2"
            data-lucide="bar-chart-2"
            className="lucide lucide-bar-chart-2 w-8 h-8 text-white transform -rotate-90"
          >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
          </svg>
        </button>
      </div>

      {isSidebarOpen && (
        <div className="mobile-menu md:hidden mobile-menu--active">
          <div className="mobile-menu-bar"></div>

          <div className="scrollable overflow-y-scroll " data-simplebar="init">
            <div className="simplebar-wrapper">
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset">
                  <div
                    className="simplebar-content-wrapper"
                    tabindex="0"
                    role="region"
                    aria-label="scrollable content"
                  >
                    <div className="simplebar-content">
                      <Link
                        onClick={toggleSidebar}
                        href="javascript:;"
                        className="mobile-menu-toggler"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          icon-name="x-circle"
                          data-lucide="x-circle"
                          className="lucide lucide-x-circle w-8 h-8 text-white transform -rotate-90"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>{" "}
                      </Link>
                      <ul className="scrollable__content  py-2">
                        {userData.userType === ROLES.SUPER_ADMIN ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.CAMPUS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.PENDING_FOR_REVIEW}
                                redirectionUrl={ROUTE_STRING.PENDINGREVIEW}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATES}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.STUDENT} />
                            </div>
                            {/* <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DEMODATA} />
                            </div> */}
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.PAYMENTS}
                                redirectionUrl={
                                  ROUTE_STRING.SUPER_ADMIN_PAYMENT
                                }
                              />
                            </div>
                            {/* <div onClick={toggleSidebar}><MobilesideTab tabName={ROUTE_STRING.ADDRESS}/></div> */}
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.SLIDERS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.NEWS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.TEST} />
                            </div>

                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TESTINVITATION}
                                redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.MEETINGS} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CAMPUSPLANING}
                                redirectionUrl={ROUTE_STRING.CAMPUS_PLANING}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATEPLANING}
                                redirectionUrl={ROUTE_STRING.CORPORATE_PLANING}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.HIRINGPROCESS}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INTERVIEW_PANEL}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.ASSESSMENT}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.OFFERLETTER}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TRANINGVIDEOS}
                              />
                            </div>
                          </>
                        ) : userData.userType === ROLES.CAMPUS ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATES}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.STUDENT} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CORPORATEPLANING}
                                redirectionUrl={ROUTE_STRING.CORPORATE_PLANING}
                              />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TESTINVITATION}
                                redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INVITATION_MGMT}
                                redirectionUrl={ROUTE_STRING.CAMPUS_INVITATION}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INVITATION_SENT_STUDENT}
                                redirectionUrl={
                                  ROUTE_STRING.SENT_INVITATION_TO_STUDENT
                                }
                              />
                            </div>
                            <MobilesideTab
                              tabName={ROUTE_STRING.RECEIVED_INVITATION}
                              redirectionUrl={
                                ROUTE_STRING.RECEIVE_CAMPUS_INVITATION
                              }
                            />

                            <MobilesideTab tabName={ROUTE_STRING.VIDEOCALL} />
                          </>
                        ) : userData.userType === ROLES.CORPORATE ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.CAMPUS} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.CAMPUSPLANING}
                                redirectionUrl={ROUTE_STRING.CAMPUS_PLANING}
                              />
                            </div>
                            <div>
                              <MobilesideTab tabName={ROUTE_STRING.MEETINGS} />
                            </div>

                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TESTINVITATION}
                                redirectionUrl={ROUTE_STRING.TEST_INVITATION}
                              />
                            </div>
                            <div>
                              <MobilesideTab tabName={ROUTE_STRING.TEST} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INVITATION_MGMT}
                                redirectionUrl={
                                  ROUTE_STRING.CORPORATE_INVITATION
                                }
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.RECEIVED_INVITATION}
                                redirectionUrl={
                                  ROUTE_STRING.RECEIVE_CAMPUS_INVITATION
                                }
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.INTERVIEW_PANEL}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.ASSESSMENT}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.HIRINGPROCESS}
                              />
                            </div>
                          </>
                        ) : userData.userType === ROLES.ON_STUDENT ? (
                          <>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.DASHBOARD} />
                            </div>
                            <div onClick={toggleSidebar}>
                              <MobilesideTab tabName={ROUTE_STRING.STUDENT} />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.TEST_FOR_STUDENT}
                              />
                            </div>
                            <div>
                              <MobilesideTab
                                tabName={ROUTE_STRING.RECEIVED_INVITATION}
                                redirectionUrl={
                                  ROUTE_STRING.RECEIVE_STUDENT_INVITATION
                                }
                              />
                            </div>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="simplebar-placeholder"
                style={{ width: " 0px;", height: "0px;" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-horizontal"
              style={{ visibility: "hidden" }}
            >
              <div
                className="simplebar-scrollbar"
                style={{ width: "0px", display: "none;" }}
              ></div>
            </div>
            <div
              className="simplebar-track simplebar-vertical"
              style={{ visibility: "hidden;" }}
            >
              <div className="simplebar-scrollbar"></div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="flex mt-[4.7rem] md:mt-0 ">
          <nav className="side-nav ">
            <a href="" className="intro-x flex items-center  pt-4">
              <Image
                src={lglogo}
                alt="Easycampus Logo"
                className="hidden xl:block text-white text-lg"
              />
            </a>
            <a href="" className="intro-y ">
              <Image src={logo} className="xl:hidden block w-16" alt="logo" />
              <p className=" text-white">{userData.userType}</p>
            </a>
            <div className="side-nav__devider my-6"></div>

            <ul>
                <>
                  <SidebarTab tabName={ROUTE_STRING.CAMPUS} />
                
                  <SidebarTab tabName={ROUTE_STRING.CORPORATES} />
                  <SidebarTab tabName={ROUTE_STRING.STUDENT} />
                 

                  
                 </>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
