
export const COLOR_CODE = {
    BLUE_600: "blue-600",
    RED_600:'red-600',
    GREEN_COLOR_CODE: "#00FF00"
}

export const COLOR_ATTRIBUTE = {
    BG: "bg",
    BORDER:"border",
}
export const BUTTON_HOVER={
    BUTTON_HOVER:"#1c3faa"
}


